table {
	border-collapse: collapse;
	border-radius: 6px;
}

.Leagues th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: center;
}

.Leagues {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: 100%;
	margin-top: 7rem;
	/*height: 200px;*/
}

td {
	text-align: center;
	/* border: 2px solid black; */
	padding: 10px;
}

h2 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#leagues {
	width: 40%;
	height: 200px;
}

#leagues tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#leagues tr:nth-child(odd) {
	background-color: white;
}

thead {
	padding: 10px;
}

/* button {
	margin: 0rem !important;
} */

button:hover {
	background-color: white;
}

.navlinks {
	-webkit-appearance: button;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: #e0e0e0;
	border-radius: 0;
	margin: 0rem !important;
	padding: 1px 6px;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	color: black;
}

.navlinks:hover {
	background-color: white;
	color: black;
}

/* #newLeagueButton {
	text-align: center;
	padding: 10px;
	color: white;
	border-radius: 4px;
	background-color: #92562e !important;
	border: #92562e !important;
	margin-left: 10rem !important;
	margin-bottom: 1rem;
} */

/* AdminCoachCodeOfConductList list:  */
* {
	box-sizing: border-box;
}

#myInput {
	/* background-image: url('/css/searchicon.png'); */
	background-position: 10px 12px;
	background-repeat: no-repeat;
	width: 100%;
	font-size: 16px;
	padding: 12px 20px 12px 40px;
	border: 1px solid #ddd;
	margin-bottom: 12px;
}

#myUL {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

#myUL li a {
	border: 1px solid #ddd;
	margin-top: -1px; /* Prevent double borders */
	background-color: #f6f6f6;
	padding: 12px;
	text-decoration: none;
	font-size: 18px;
	color: black;
	display: block;
}

#myUL li a:hover:not(.header) {
	background-color: #eee;
}

/* //
//
//
//
//
//
//
// */

.buttonButton {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	/* border-color: black; */
	width: auto;
	margin: 0rem !important;
}

.modalText {
	font-size: x-large;
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

@media (max-width: 900px) {
	.modalText {
		font-size: medium;
	}
}
