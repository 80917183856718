.register-form {
	list-style: none;
	margin: 0 auto;
	padding: 1rem;
	width: 40%;
	/* max-width: 40rem; */
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 6px;
	background: white;
}

@media (max-width: 900px) {
	.register-form {
		width: 95%;
	}
}

.loginLink {
	cursor: pointer;
}

.fg-line {
	position: relative;
	vertical-align: top;
}

.form-group {
	margin-bottom: 15px;
}

/* //
//
//
//
//
//
// */
.rcard {
	font-size: 15px;
	margin-top: 20px;
	position: relative;
	background: #e0e0e0;
	margin-bottom: 30px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rcard .rcard-header:not(.ch-alt) {
	padding: 23px 25px;
}

.rcard .rcard-header {
	background-color: #f7f7f7;
	margin-bottom: 23px;
}

.rcard .rcard-header {
	position: relative;
}
@media screen and (min-width: 768px) {
	.rcard .rcard-header:not(.ch-alt) {
		padding: 23px 25px;
	}
}

@media screen and (max-width: 991px) {
	.rcard .rcard-header:not(.ch-alt) {
		padding: 18px;
	}
}

.rcard .rcard-header h2 {
	margin: 0;
	line-height: 100%;
	font-size: 17px;
	font-weight: 400;
	color: #5e5e5e;
}

.rcard .rcard-header h2.inline {
	display: inline-block;
	margin-right: 24px;
}

.rcard .rcard-header h2 small,
.rcard .rcard-header h2 .field-validation-error {
	display: block;
	margin-top: 8px;
	color: #aeaeae;
	line-height: 160%;
}

.rcard .rcard-header.dark-bg h2 {
	color: #fff;
}

.rcard-header:not(.ch-alt):not([class*='bgm-']) + .rcard-padding {
	padding-top: 0;
}

.rcard .rcard-body.rcard-padding {
	padding: 18px;
}

@media screen and (min-width: 768px) {
	.rcard .rcard-body.rcard-padding {
		padding: 23px 26px;
	}
}

@media screen and (max-width: 991px) {
	.rcard .rcard-body.rcard-padding {
		padding: 18px;
	}
}

.rcard .rcard-body.rcard-padding-sm {
	padding: 15px;
}

.rcard .rcard-header.dark-bg h2 small,
.rcard .rcard-header.dark-bg h2 .field-validation-error {
	color: #eee;
}

.rcard .rcard-header.dark-bg .title-addon {
	color: #fff;
}

@media screen and (min-width: 768px) {
	.rcard .rcard-header.ch-alt {
		padding: 23px 26px;
	}
}

@media screen and (max-width: 991px) {
	.rcard .rcard-header.ch-alt {
		padding: 18px 18px 28px;
	}
}

.rcard .rcard-header.ch-alt:not([class*='bgm-']) {
	background-color: #f7f7f7;
}

.rcard .rcard-header[class*='bgm-'] h2,
.rcard .rcard-header[class*='bgm-'] h2 small,
.rcard .rcard-header[class*='bgm-'] h2 .field-validation-error {
	color: #fff;
}

.rcard .rcard-header .actions {
	position: absolute;
	right: 10px;
	z-index: 2;
	top: 15px;
}

.rcard .rcard-header .btn-float {
	right: 25px;
	bottom: -23px;
	z-index: 1;
}
/* //
//
//
//
//
//
// */
.rcard .form-group.net .rform-control {
	display: inline-block;
	width: 90%;
}

.card .form-group.net label {
	display: block;
}

.rform-control:not(.fc-alt) {
	border-left: 0;
	border-right: 0;
	border-top: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0;
}

.rform-control {
	box-shadow: none !important;
	resize: none;
	color: #5e5e5e;
}

.rform-control {
	display: block;
	width: 100%;
	height: 35px;
	padding: 6px 12px;
	font-size: 13px;
	line-height: 1.42857;
	color: #000;
	background-color: #fff;
	background-image: none;
	border: 1px solid #e0e0e0;
	border-radius: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
/* //
//
//
//
//
//
// */
.c-red {
	color: #f44336 !important;
}
/* //
//
//
//
//
//
// */
.col-sm-5 {
	width: 41.66667%;
}

.col-sm-6 {
	width: 50%;
}

.col-sm-12 {
	width: 100%;
}

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12 {
	float: left;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

.buttonButton {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	width: auto;
}

.modalText {
	font-size: x-large;
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

@media (max-width: 700px) {
	.modalText {
		font-size: medium;
	}
}

.okButton:hover {
	background: #9f9f9f;
	color: black;
}
