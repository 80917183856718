.main-navigation__menu-btn {
	width: 5rem;
	height: 3rem;
	background: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-right: 2rem;
	cursor: pointer;
}

.main-navigation__menu-btn span {
	display: block;
	width: 3rem;
	height: 2.5px;
	background: white;
}

.main-navigation__title {
	color: white;
	text-align: right;
}

.main-navigation__title a {
	text-decoration: none;
	color: white;
}
/* //
//
//
// */
@media (max-width: 430px) {
	.main-navigation__title a {
		font-size: xx-large;
	}
}

/* //
//
//
// */
.main-navigation__title a:hover {
	text-decoration: none;
	color: rgb(249, 229, 229);
}

.main-navigation__header-nav {
	display: none;
}

.main-navigation__drawer-nav {
	height: 100%;
}

@media (min-width: 768px) {
	.main-navigation__menu-btn {
		display: none;
	}

	.main-navigation__header-nav {
		display: block;
	}
}
