table {
	border-collapse: collapse;
	border-radius: 6px;
}

.AddGames th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: center;
}

@media (max-width: 430px) {
	.AddGames th {
		font-size: x-small;
	}
}

.AddGames {
	background-color: white;
	overflow-x: auto;
	width: 100%;
	margin-top: 7rem;
	/*height: 200px;*/
}

.AddEvents th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: center;
}

@media (max-width: 430px) {
	.AddEvents th {
		font-size: x-small;
	}
}

.AddEvents {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: 100%;
	margin-top: 7rem;
}

.whatDay {
	text-align: center;
}

select {
	background-color: white !important;
}

.text {
	font-size: 11px;
	width: 32px;
}

td {
	text-align: center;
	/* border: 2px solid black; */
	padding: 10px;
}

h2 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#games {
	width: 80%;
	height: 200px;
}

#games tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#games tr:nth-child(odd) {
	background-color: white;
}

#registrants {
	width: 30%;
	height: 200px;
}

#registrants tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#registrants tr:nth-child(odd) {
	background-color: white;
}

thead {
	padding: 10px;
}

/* button {
	margin: 0rem !important;
} */

.addGamesBtn {
	margin-top: 1rem !important;
	width: 150%;
}

/* //
//
//
//
//
//
//
//
// */

.addGameDropdownElement {
	width: auto;
}

@media (max-width: 430px) {
	.addGameDropdownElement {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}

	#registrants {
		width: 90%;
		height: 200px;
	}
}

@media (max-width: 700px) {
	.addGameDropdownElement {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

@media (max-width: 800px) {
	#registrants {
		width: 70%;
		height: 200px;
	}
}

@media (max-width: 1700px) {
	.addGameDropdownElement {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

/* //
//
//
//
//
//
//
//
// */

.selectLeagueDropdown {
	width: 80%;
}

@media (max-width: 430px) {
	.selectLeagueDropdown {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

@media (max-width: 700px) {
	.selectLeagueDropdown {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

@media (max-width: 1700px) {
	.selectLeagueDropdown {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

/* //
//
//
//
//
//
//
//
// */
@media (max-width: 430px) {
	.selectTeamDropdown {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

@media (max-width: 700px) {
	.selectTeamDropdown {
		width: auto;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

@media (max-width: 1700px) {
	.selectTeamDropdown {
		width: 100%;
		color: black;
		border-radius: 2%;
		border-color: black;
		border-width: 1px;
	}
}

.suspension-form {
	list-style: none;
	margin: 0 auto;
	padding: 1rem;
	width: 90%;
	max-width: 40rem;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 6px;
	background: white;
}

.buttonButton {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	/* border-color: black; */
	width: auto;
}

.modalText {
	font-size: x-large;
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

@media (max-width: 900px) {
	.modalText {
		font-size: medium;
	}
}
