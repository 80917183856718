@media (max-width: 430px) {
	.rosterModalTable {
		width: 100% !important;
	}
	.orgDropdown {
		width: 70%;
	}
	.goalieOrTaxi {
		margin-bottom: 1rem !important;
	}
}

@media (min-width: 1200px) {
	.orgDropdown {
		width: 100%;
	}
}
@media (min-width: 750px) {
	.rosterModalTable {
		width: 80%;
	}
	.orgDropdown {
		width: 50%;
	}
}

@media (min-width: 1600px) {
	.rosterModalTable {
		width: 80%;
	}
	.orgDropdown {
		width: 50%;
	}
}

.button2:hover {
	color: black !important;
	background: #976848 !important;
	border-color: #976848 !important;
}

table {
	border-collapse: collapse;
	border-radius: 6px;
}
.scheduleselector {
	background: white;
	border-width: 1px;
	width: 100%;
	font-size: medium;
	font-family: Montserrat, Work Sans, sans-serif;
}

/* table td {
	text-align: left !important;
} */

.RegLeagues th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: left;
	font-size: medium;
}
.RegLeaguesNonAdmin th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #090085;
	text-align: left;
	font-size: medium;
}

.RegLeagues {
	background-color: #e0e0e0;
	width: 80%;
	margin: auto;
	margin-top: 7rem;
}
.RegLeaguesNonAdmin {
	background-color: #e0e0e0;
	/* overflow-x: auto; */
	width: 60%;
	margin: auto;
	margin-top: 7rem;
	/*height: 200px;*/
}

@media (max-width: 900px) {
	.RegLeagues {
		background-color: #e0e0e0;
		/* overflow-x: auto; */
		width: auto;
		margin: auto;
		margin-top: 7rem;
		/*height: 200px;*/
	}
	.RegLeaguesNonAdmin {
		background-color: #e0e0e0;
		/* overflow-x: auto; */
		width: auto;
		margin: auto;
		margin-top: 7rem;
		/*height: 200px;*/
	}
}

#regLeagues td {
	text-align: left;
	padding: 10px;
}
#regLeaguesNonAdmin td {
	text-align: left;
	padding: 10px;
}

h2 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#regLeagues {
	width: 100%;
	height: auto;
}
#regLeaguesNonAdmin {
	width: 100%;
	height: auto;
}

@media (max-width: 430px) {
	#regLeagues {
		width: auto;
	}
	#regLeaguesNonAdmin {
		width: auto;
	}
	.RegLeagues th {
		font-size: x-small;
	}
	.regLeagues th {
		font-size: x-small;
	}
	#regLeaguesNonAdmin th {
		font-size: xx-small;
	}
	.scheduleselector {
		font-size: x-small;
		color: black;
		width: 100%;
		align-content: center;
		border-radius: 2%;
		border-color: black;
	}
}

@media (min-width: 700px) {
	#regLeagues {
		width: 100%;
	}
	#regLeaguesNonAdmin {
		width: 100%;
	}
	.RegLeagues th {
		font-size: x-small;
	}
	.RegLeaguesNonAdmin th {
		font-size: x-small;
	}
	.scheduleselector {
		font-size: x-small;
		width: 100%;
		color: black;
		border-radius: 2%;
		border-color: black;
	}
}

#regLeagues tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#regLeagues tr:nth-child(odd) {
	background-color: white;
}

#regLeaguesNonAdmin tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#regLeaguesNonAdmin tr:nth-child(odd) {
	background-color: white;
}

thead {
	padding: 10px;
}

/* button {
	margin-right: 1rem !important;
} */

button:hover {
	background-color: white;
}

.navlinks {
	-webkit-appearance: button;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: #e0e0e0;
	border-radius: 0;
	margin: 0rem !important;
	padding: 1px 6px;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	color: black;
}

.navlinks:hover {
	background-color: white;
	color: black;
}

/* //
//
//
//
//
//
//
// */

.buttonButton2 {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	width: auto;
	margin-right: 1rem;
}
/* //
//
//
//
//
//
//
// */
.paragraph {
	padding-left: 10%;
	padding-right: 10%;
	text-align: center;
}

.regLeagueSelector {
	background: white;
	border-width: 1px;
	width: 100%;
	font-size: medium;
	font-family: Montserrat, Work Sans, sans-serif;
}

@media (max-width: 430px) {
	.regLeagueSelector {
		font-size: x-small;
		color: black;
		width: 100%;
		align-content: center;
		border-radius: 2%;
		border-color: black;
	}
}

@media (min-width: 700px) {
	.regLeagueSelector {
		font-size: x-small;
		width: 100%;
		color: black;
		border-radius: 2%;
		border-color: black;
	}
}

/* //
//
//
//
//
//
//
// */

.buttonButton2 {
	color: black;
	border-width: 2px;
	border-style: outset;
	border-color: black;
	border-radius: 0px;
	width: auto;
	margin-right: 1rem;
}

.modalText {
	font-size: x-large;
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

@media (min-width: 700px) {
	.modalText {
		font-size: medium;
	}
}

.okButton:hover {
	background: #9f9f9f;
	color: black;
}
/* //
//
//
//
//
// */
#regPlayers {
	height: auto;
	width: 100%;
	overflow-y: scroll;
}
#regPlayers td {
	text-align: left;
	padding: 10px;
	font-size: small;
}
#regPlayers thead {
	color: black;
}

@media (min-width: 700px) {
	#regPlayers {
		width: 100%;
	}

	.regPlayers th {
		font-size: x-small;
	}
}

#regPlayers tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#regPlayers tr:nth-child(odd) {
	background-color: white;
}

.RegPlayers th {
	border-bottom: 1px solid black;
	color: white;
	padding: 10px;
	background-color: #92562e;
	text-align: left;
	font-size: medium;
}

.RegPlayers {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: 70%;
	margin: auto;
	margin-top: 7rem;
	/*height: 200px;*/
}

@media (min-width: 1200px) {
	.RegPlayers {
		background-color: #e0e0e0;
		overflow-x: auto;
		width: 100%;
		margin: auto;
		margin-top: 7rem;
		/*height: 200px;*/
	}
	.scheduleselector {
		font-size: x-small;
		width: 100%;
		color: black;
		border-radius: 2%;
		border-color: black;
	}
	.RegLeaguesNonAdmin th {
		font-size: medium;
	}
}

/*This is where I control what prints and how*/
@media print {
	h1 {
		display: none;
	}
	button {
		display: none;
	}
	.paragraph {
		display: none;
	}
	.RegPlayers th {
		font-size: xx-small;
	}
	#regPlayers td {
		font-size: xx-small;
	}
	.address {
		display: none;
	}
}

.textArea {
	-webkit-appearance: none;
	appearance: none;
	background-clip: padding-box;
	background-color: lightgray;
	border-radius: 0.25rem;
	color: #212529;
	display: block;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	width: 100%;
}

.dropdownForm {
	padding-left: 30%;
	padding-right: 30%;
}

.orgDropdown {
	border-width: 3px;
	font-family: Montserrat, Work Sans, sans-serif;
	font-size: medium;
	margin-left: auto;
	margin-right: auto;
	display: grid;
}

.emailInput {
	background-color: lightgray !important;
}

.centeredButton {
	margin-bottom: 2rem !important;
	margin-top: 1rem !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

@media (min-width: 1200px) {
	.RegLeagues th {
		font-size: medium;
	}
}

.saveCommittedBtn {
	display: block;
	margin-left: auto !important;
	margin-right: auto !important;
	width: 100%;
	margin-bottom: 5rem !important;
}
