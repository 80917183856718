.pmodal {
	z-index: 100;
	margin: 0 auto;
	display: block !important;
	position: fixed;
	left: 50%;
	background: white;
	right: 0;
	top: 9vh !important;
	border-radius: 8px;
}
/********************************************************** //
//
//    iphone
//
// *********************************************************/
@media (max-width: 430px) {
	.pmodal {
		margin-left: -50%;
		margin-top: 20%;
		text-align: center;
	}
}
/********************************************************** //
//
//    Connies iPad
//
// *********************************************************/
@media (min-width: 768px) {
	.pmodal {
		margin-left: -50%;
		margin-top: 20%;
		text-align: center;
	}
}
/********************************************************** //
//
//    Connies laptop
//
// *********************************************************/
@media (min-width: 1200px) {
	.pmodal {
		width: 30% !important;
		height: 30% !important;
		margin-top: 10%;
		margin-left: -15%;
		text-align: center;
	}
}
/********************************************************** //
//
//    My desktop
//
// *********************************************************/
@media (min-width: 1600px) {
	.pmodal {
		width: 30% !important;
		height: 30% !important;
		margin-top: 10%;
		margin-left: -15%;
		text-align: center;
	}
}

.pmodal__header {
	width: 100%;
	padding: 1rem 0.5rem;
	background: #ff0011;
	color: white;
	border-radius: 8px 8px 0 0;
}

.pmodal__header h2 {
	margin: 0.5rem;
}

.pmodal__content {
	padding: 0.5rem 0.5rem;
}

.pmodal__footer {
	padding: 0.5rem 0.5rem;
}

.modal-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.modal-enter-active {
	transform: translateY(0);
	opacity: 1;
	transition: all 200ms;
}

.modal-exit {
	transform: translateY(0);
	opacity: 1;
}

.modal-exit-active {
	transform: translateY(-10rem);
	opacity: 0;
	transition: all 200ms;
}
