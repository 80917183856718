.home-nav-links {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home-nav-links li {
	margin: 1rem;
}

.home-nav-links a {
	border: 1px solid transparent;
	font-weight: bold;
	color: gray;
	text-decoration: none;
	padding: 0.5rem;
}

.home-nav-links a:hover,
.home-nav-links a:active,
.home-nav-links a.active {
	background: #48667e;
	border-color: #48667e;
	font-weight: bold;
	color: white;
	border-radius: 4px;
}

.home-nav-links button {
	cursor: pointer;
	border: 1px solid gray;
	color: gray;
	background: transparent;
	padding: 0.5rem;
	font: inherit;
}

.home-nav-links button:focus {
	outline: none;
}

.home-nav-links button:hover,
.home-nav-links button:active {
	background: black;
	color: white;
}
/****************************************** //
//
//  Connies iPad
//
//*******************************************/
@media (min-width: 768px) {
	.home-nav-links {
		flex-direction: row;
	}

	.home-nav-links li {
		margin: 0 0.5rem;
	}

	.home-nav-links a {
		font-weight: bold;
		color: white;
		text-decoration: none;
		font-size: xx-small;
	}

	.home-nav-links button {
		border: 1px solid white;
		color: white;
		background: transparent;
	}

	.home-nav-links button:hover,
	.home-nav-links button:active {
		background: #292929;
		color: white;
	}
	.navigationTitle {
		font-size: small;
	}
}
/****************************************** //
//
//
//  connies laptop
//
//
//*******************************************/
@media (min-width: 1200px) {
	.home-nav-links a {
		font-weight: bold;
		color: white;
		text-decoration: none;
		font-size: medium;
	}
	.navigationTitle {
		font-size: xx-large;
	}
}

/****************************************** //
//
//
//  my desktop
//
//
//*******************************************/
@media (min-width: 1600px) {
	.home-nav-links a {
		font-weight: bold;
		color: white;
		text-decoration: none;
		font-size: medium;
	}
	.navigationTitle {
		font-size: xx-large;
	}
}
