/* table {
	border-collapse: collapse;
	margin: auto;
} */

table {
	width: 100%;
	height: 200px;
	border: 0;
	border-collapse: collapse;
	margin: auto;
	overflow-x: auto;
}

.Leaders {
	background-color: white;
	overflow-x: auto;
	/*width: 100%;*/
	/*height: 200px;*/
}

.Leaders th {
	border-bottom: 1px solid black;
	color: black;
	background-color: #ff0011;
	text-align: center;
}

td {
	text-align: center;
	/* border: 2px solid black; */
}

h2 {
	text-align: center;
}

/* This right here seemed to be the thing that got the table
to look good on mobile. */
#players {
	width: 40%;
	height: 200px;
}

@media (max-width: 420px) {
	#players {
		width: 100%;
	}
}

#players tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#players tr:nth-child(odd) {
	background-color: white;
}

thead {
	color: white;
}
