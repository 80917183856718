.carousel-inner img {
	width: 35%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	object-fit: cover;
	object-position: center;
}

.carousel-caption {
	position: relative !important;
	left: 0% !important;
	right: 0% !important;
}

.carousel-inner {
	width: 75% !important;
	margin-left: auto;
	margin-right: auto;
	border-radius: 4px;
	background-color: white;
}

.carousel {
	margin-left: auto;
	margin-right: auto;
}

.carousel-heading {
	margin-top: 1rem;
	color: #090085;
}

.carousel-text {
	color: #090085;
	padding-left: 10%;
	padding-right: 10%;
}
.carousel-control-next,
.carousel-control-prev {
	filter: invert(100%) !important;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
	width: 5rem !important;
	height: 5rem !important;
}
/* //
//
//
//
//
// */
.headerItem {
	font-weight: bold;
	text-transform: uppercase;
	color: #f8f9fa !important;
}

.headerItem:hover {
	color: #ffc107 !important;
}

.img-thumbnail {
	max-width: none !important;
	/* width: 60% !important; */
	border: none !important;
	background-color: white !important;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.brookline {
	width: 43% !important;
}
.bills {
	width: 58% !important;
}
.venom {
	width: 48% !important;
}
.pennHills {
	width: 40% !important;
}
.riverview {
	width: 48% !important;
}
.southHills {
	width: 48% !important;
}
.cranberry {
	width: 50% !important;
}
.murrysville {
	width: 60% !important;
}
.mckeesport {
	width: 60% !important;
}
.teampitt {
	width: 60% !important;
}
.tournamentSchedule {
	width: 30% !important;
}

/* //
//
//
//
//
//
// */
body {
	font-family: 'Montserrat', sans-serif;
}

.homeHeader {
	height: 65vh;
	background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
		url(../images/pbhlLogo.PNG) no-repeat center center / 100%;
}
.linkToCommitmentButton {
	display: block;
	margin-left: auto !important;
	margin-right: auto !important;
	color: white;
	text-decoration: none;
	background: #f46c06;
	border: none;
	border-radius: 7px;
	padding: 5px 25px 5px 25px;
	font-weight: 800;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}
.linkToCommitmentButton:hover {
	background: #f78329;
}
.iconRow {
	margin-left: auto !important;
	margin-right: auto !important;
	display: block !important;
}
/************************************* //
//
//  iPhone 
//
//*************************************/
@media (max-width: 430px) {
	.homeHeader {
		height: 57vh;
		background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
			url(../images/pbhlLogo.PNG) no-repeat center center / cover;
	}
	.headingText {
		font-weight: 700 !important;
		font-size: calc(1rem + 3.9vw) !important;
		margin-top: 3rem !important;
	}
	.subheadingText {
		margin-top: 3rem;
		font-weight: 700 !important;
	}
	.banner {
		top: 27%;
	}
	.missionQuote {
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		font-size: small !important;
	}
	.description {
		font-size: small;
	}
	.displayProperty {
		font-size: 2.5rem;
		font-weight: 300;
		line-height: 1.2;
	}
	.underline-blue {
		margin-top: 2.5rem;
		width: 50%;
		border: 3px solid #090085;
		margin-left: auto;
		margin-right: auto;
	}
	.underline-blue2 {
		margin-top: 2.5rem;
		width: 50%;
		border: 1px solid #090085;
		margin-left: auto;
		margin-right: auto;
	}
	.carousel-inner img {
		width: 60%;
		height: 50%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.tournamentSchedule {
		width: 80% !important;
	}
	.linkToCommitmentButton {
		font-size: smaller;
		width: 38% !important;
	}
	.commitmentFormButton {
		font-size: small;
		padding: 4%;
	}
}
/************************************* //
//
//  iPhone landscape (I think)
//
//*************************************/
@media (min-width: 600px) {
	.homeHeader {
		height: 88vh;
		background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
			url(../images/pbhlLogo.PNG) no-repeat center center / cover;
	}
	.headingWriting {
		margin-top: 6rem;
	}
	.banner {
		top: 30%;
	}
	.buttonRow {
		display: flex !important;
	}
}
/************************************* //
//
//  connies ipad 
//
//*************************************/
@media (min-width: 768px) {
	.banner {
		top: 18%;
	}
	.linkToCommitmentButton {
		width: 45% !important;
	}
}
/************************************* //
//
//  connies ipad landscape
//
//*************************************/
@media (min-width: 1024px) {
	.banner {
		top: 18%;
	}
	.bottomRow {
		margin-left: 25% !important;
	}
}
/************************************* //
//
//  connies laptop:  1536x864
//
//*************************************/
@media (min-width: 1200px) {
	.homeHeader {
		height: 65vh;
		background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
			url(../images/pbhlLogo.PNG) no-repeat center center / cover;
	}
	.headingText {
		margin-top: -10rem;
		margin-bottom: 2rem;
	}
	.banner {
		top: 22%;
	}
	.missionQuote {
		margin-left: auto;
		margin-right: auto;
		width: 70%;
	}
	.underline-blue {
		margin-top: 2.5rem;
		width: 20%;
		border: 3px solid #090085;
		margin-left: auto;
		margin-right: auto;
	}
	.underline-blue2 {
		margin-top: 2.5rem;
		width: 60%;
		border: 3px solid #090085;
		margin-left: auto;
		margin-right: auto;
	}
	.displayProperty {
		font-size: 4.5rem;
		font-weight: 300;
		line-height: 1.2;
	}
	.bottomRow {
		margin-left: 25% !important;
	}
	.linkToCommitmentButton {
		width: 15% !important;
	}
}
/*********************************** //
//
//  my desktop:  1920x1080
//
//*********************************** */
@media (min-width: 1600px) {
	.homeHeader {
		height: 65vh;
		background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
			url(../images/pbhlLogo.PNG) no-repeat center center / cover;
	}
	.headingText {
		margin-top: -18rem;
		margin-bottom: 2rem;
	}
	.banner {
		top: 30%;
	}
	.missionQuote {
		margin-left: auto;
		margin-right: auto;
		width: 70%;
	}

	.underline-blue {
		margin-top: 2.5rem;
		width: 20%;
		border: 3px solid #090085;
		margin-left: auto;
		margin-right: auto;
	}
	.underline-blue2 {
		margin-top: 2.5rem;
		width: 60%;
		border: 1.5px solid #090085;
		margin-left: auto;
		margin-right: auto;
	}
	.displayProperty {
		font-size: 4.5rem;
		font-weight: 300;
		line-height: 1.2;
	}
	.bottomRow {
		margin-left: 25% !important;
	}
	.commitmentFormButton {
		font-size: x-large;
		padding: 1%;
	}
}
/* //
//
//
//
//
// */
#player {
	width: 50% !important;
}

.nav-menu {
	background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
	padding: 30px;
	transition: all 0.7s;
}

.menu-item {
	font-size: 13px;
	letter-spacing: 1px;
	color: #eee;
	transition: color 0.3s;
}

.menu-item:hover {
	color: #70aed2;
}

.nav-active {
	color: #70aed2;
}

.line1,
.line2,
.line3 {
	width: 23px;
	height: 3px;
	margin: 5px;
	transition: all 0.4s;
}

.change .line1 {
	transform: rotate(-45deg) translate(-5px, 6px);
}

.change .line2 {
	opacity: 0;
}

.change .line3 {
	transform: rotate(45deg) translate(-5px, -6px);
}

.custom-navbar {
	padding: 5px 30px;
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
}

.banner {
	position: absolute;
	/* top: 30%; */
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
}

.banner-heading {
	animation-name: animate;
	animation-duration: 2s;
}

.banner-par {
	animation-name: animate;
	animation-duration: 2s;
	animation-delay: 0.5s;
	animation-fill-mode: backwards;
}

@keyframes animate {
	0% {
		transform: translateX(-100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.mission {
	background: gray;
	position: absolute;
}

.underline {
	width: 15% !important;
	border: 3px solid gray;
	margin: auto;
}

.underline-red {
	width: 20% !important;
	border: 3px solid red;
	margin: auto;
}

.text-sh-light-blue {
	color: #316aad;
}

.text-sh-dark-blue {
	color: gray;
}

.text-sh-red {
	color: #ff0011;
}

.fromLeft {
	animation-name: fromLeft;
	animation-duration: 3s;
}

.fromRight {
	animation-name: fromRight;
	animation-duration: 3s;
}

@keyframes fromLeft {
	0% {
		transform: translateX(-120px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fromRight {
	0% {
		transform: translateX(30px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.card-shadow {
	box-shadow: 5px 8px 20px #444;
}

.heading {
	position: absolute;
	top: 70%;
	right: 0;
	font-size: 14px;
	letter-spacing: 1px;
	background: #222;
	width: 70%;
	cursor: pointer;
	opacity: 0.7;
	transition: all 0.5s;
}

.heading:hover {
	opacity: 1;
}

.underline-dark {
	width: 150px;
	border: 3px solid #888;
	margin: auto;
}

.gallery-list-item {
	color: #777;
	cursor: pointer;
	user-select: none;
}

.active-item {
	color: #70aed2;
}

.card-list-item {
	border-bottom: 1px groove #eee;
	width: 55%;
	margin: auto;
}

.price-card-button {
	background: #f5593d;
	width: 130px;
	border-radius: 25px;
	box-shadow: 5px 8px 18px #000;
	transition: all 0.2s;
}

.price-card-button:hover {
	background: #f9793d;
	transform: translate(-2px);
}

.moveFromLeft {
	animation-name: moveFromLeft;
	animation-duration: 2s;
}

.moveFromRight {
	animation-name: moveFromRight;
	animation-duration: 2s;
}

.moveFromBottom {
	animation-name: moveFromBottom;
	animation-duration: 2s;
}

@keyframes moveFromLeft {
	0% {
		transform: translateX(-100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes moveFromRight {
	0% {
		transform: translateX(100px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes moveFromBottom {
	0% {
		transform: translateY(100px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/* this section is cut in two at a 105 degree angle where HALF (50%) 
of the background is very dark (.95 opacity).
The second 50% value is the black line.  If I make it something else, like
60%, you'll see the difference in how the line fades out
*/
.contact {
	/* background: linear-gradient(
			105deg,
			rgba(21, 31, 32, 1) 0%,
			rgba(21, 31, 32, 0.95) 50%,
			transparent 50%
		),
		url(../images/form-img.jpeg) center center / cover; */
}

.input {
	background: transparent;
	border-color: transparent;
	border-bottom: 2px solid #ccc;
	border-radius: 0;
	transition: all 0.8s;
}

.input:focus {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: #fff;
	border-bottom: 2px solid #f5593d;
}

.submit-button {
	background: #f5593d;
	color: #eee;
	transition: all 0.3s;
}

.submit-button:hover {
	background: #f9793d;
	transform: translateY(-3px);
}

.label {
	color: #777;
	display: block;
	margin-top: -70px;
	margin-left: 4px;
	font-size: 13px;
	transition: all 0.3s;
}

.input:placeholder-shown + .label {
	transform: translate(20px, 20px);
	opacity: 0;
	visibility: hidden;
}

@media (max-width: 992px) {
	.contact {
		background: linear-gradient(rgba(21, 31, 32, 1), rgba(21, 31, 32, 0.95));
	}
	.contact-form {
		width: 80%;
		margin: auto;
	}
}

.fa-cogs,
.fa-thumbs-up,
.fa-handshake {
	color: gray;
}

.middle {
	height: 1000px;
}

.container-dark {
	background-color: gray !important;
}
.container-gray {
	background-color: #e0e0e0 !important;
}

.logo-container {
	width: 15% !important;
	height: auto !important;
}

.footer-h5 {
	text-align: center;
}

.footer-p {
	text-align: center;
}

.footer {
	position: absolute;
}

.footer-link {
	text-decoration: none;
	color: #eee;
	font-size: 13px;
	transition: all 0.3s;
}

.footer-link:hover {
	color: #708ed2;
	text-decoration: none;
}

.section {
	position: relative;
	background-color: white;
}

.logoImage1 {
}

/* Some of these are set just because I'm trying to override bootstrap settings */

.icon {
	font-size: 5em;
}

.footer-background {
	background-color: #090085;
}

@media (max-width: 700px) {
	.underline-white {
		width: 60%;
		border: 3px solid white;
		margin: auto;
	}

	.underline {
		width: 40% !important;
	}

	.logoRow {
		display: none;
	}
	.carousel {
		width: 100%;
	}

	.carousel-text {
		font-size: small;
	}

	.carousel-header {
		font-size: x-large;
	}

	.p-5 {
		padding: 0.5rem;
	}

	.logo-container {
		width: 35% !important;
		height: 3rem !important;
	}

	p {
		margin-bottom: 4rem !important;
	}
	.caption {
		margin-bottom: 0.75rem !important;
	}

	.icon {
		font-size: 3em;
	}
}

.logoRow {
	width: 100%;
	padding-left: 5rem;
	background-color: white;
}

.vikings {
	width: 25%;
}
.barbarians {
	width: 25%;
}
.invaders {
	width: 25%;
}
.otherTeam {
	width: 25%;
}

.archives {
}

.videos {
	background-color: white;
}

.content {
	margin-right: 15%;
	margin-left: 15%;
}
.centeredButton {
	margin-bottom: 2rem !important;
	margin-top: 1rem !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.centeredButtonSmallMargin {
	margin-bottom: 1rem !important;
	margin-top: 0.5rem !important;
	margin-left: auto !important;
	margin-right: auto !important;
}
