table td {
	text-align: center;
	/* border: 2px solid black; */
	padding: 10px 0;
	height: 10px;
}

.gameSummary-navigation__menu-btn {
	width: 5rem;
	height: 3rem;
	background: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-right: 2rem;
	cursor: pointer;
}

.gameSummary-navigation__menu-btn span {
	display: block;
	width: 3rem;
	height: 2.5px;
	background: white;
}

.gameSummary-navigation__title {
	color: white;
}

.gameSummary-navigation__title a {
	text-decoration: none;
	color: white;
}

.gameSummary-navigation__header-nav {
	display: none;
}

.gameSummary-navigation__drawer-nav {
	height: 100%;
}

@media (min-width: 768px) {
	.gameSummary-navigation__menu-btn {
		display: none;
	}

	.gameSummary-navigation__header-nav {
		display: block;
	}
}

/**/
/**/
/**/
/**/
/**/
/**/

table {
	width: auto;
	height: auto;
	border: 0;
	border-collapse: collapse;
	margin: auto;
	overflow-x: auto;
}

.h3Center {
	text-align: center;
}

.Summary {
	background-color: #e0e0e0;
	margin-top: 5rem;
	margin-bottom: 7rem;
}

.Summary th {
	border-bottom: 1px solid black;
	color: white;
	background-color: #f01;
	text-align: center;
	height: 10px;
}

.Summary {
	overflow-x: auto;
}

td {
	text-align: center;
	font-family: Work Sans, sans-serif;
}

h2 {
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

#summaryTable {
	width: 60%;
}

@media (max-width: 430px) {
	td {
		font-size: xx-small;
	}
	.Summary th {
		font-size: x-small;
	}
	#summaryTable {
		width: 110%;
	}
}

/* @media (max-width: 700px) {
	td {
		font-size: x-small;
	}
	.Summary th {
		font-size: x-small;
	}
	#summaryTable {
		width: 100%;
	}
} */

/* iPad 9th generation */
@media (max-width: 1700px) {
	#summaryTable {
		width: 90%;
	}
}

#summaryTable tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#summaryTable tr:nth-child(odd) {
	background-color: white;
}

thead {
	color: white;
}

h1 {
	/* font-family: Work Sans, sans-serif; */
	font-family: Montserrat, Work Sans, sans-serif;
	/* font-size: large; */
}

.tableDataTeamName a:hover {
	background-color: #f01;
}

.summaryBox {
	margin-left: 20%;
	width: 40%;
	height: 200px;
	font-size: x-large;
	background: white;
}

@media (max-width: 430px) {
	.summaryBox {
		width: 90%;
	}
}

/**/
/**/
/**/
/**/
.ScoringSummary {
	background-color: #e0e0e0;
	overflow-x: auto;
	width: auto;
	margin: auto;
	margin-top: 2rem;
}

.ScoringSummary th {
	border-bottom: 1px solid black;
	color: white;
	padding: 1px;
	background-color: #f01;
	text-align: center;
}

.ScoringSummary tr {
	padding: 1px;
	background-color: #f01;
	text-align: center;
}

.ScoringSummary td {
	padding: 10px;
	text-align: center;
}

#scoringSummary {
	width: 50%;
	height: auto;
}

@media (max-width: 430px) {
	#scoringSummary {
		width: 90%;
	}
}

#scoringSummary tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#scoringSummary tr:nth-child(odd) {
	background-color: white;
}
