/*It looks like this is CSS that everything else is looking at*/

table {
	width: 50%;
	height: 200px;
	border-collapse: collapse;
	margin: auto;
	overflow-x: auto;
}

.Roster {
	background-color: #e0e0e0;
	overflow-x: auto;
	margin-bottom: 3rem;
}

.Roster th {
	border-bottom: 1px solid black;
	color: black;
	background-color: #ff0011;
	text-align: center;
	font-size: large;
}

td {
	text-align: center;
	border: 2px solid black;
	font-size: medium;
}
@media (max-width: 430px) {
	td {
		font-size: small;
	}
	.Roster th {
		font-size: xx-small;
	}
}

h1 {
	text-align: center;
}

h3 {
	text-align: center;
	font-family: Montserrat, Work Sans, sans-serif;
}

#teamSchedule {
	width: 60%;
}

#roster {
	width: 50%;
	height: auto;
}
@media (max-width: 430px) {
	#roster {
		width: 100%;
	}
}
@media (max-width: 700px) {
	#roster {
		width: 80%;
	}
}
@media (max-width: 1200px) {
	#roster {
		width: 100%;
	}
}

#roster tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#roster tr:nth-child(odd) {
	background-color: white;
}

#teamSchedule tr:nth-child(even) {
	background-color: rgb(203, 200, 200);
}

#teamSchedule tr:nth-child(odd) {
	background-color: white;
}

img {
	width: 9%;
}

@media (max-width: 430px) {
	#teamSchedule {
		width: auto;
	}
}

@media (max-width: 1700px) {
	#teamSchedule {
		width: 90%;
	}
}

thead {
	color: white;
}

.photoDiv {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.playerPhoto {
	display: block;
	margin: auto;
	border-radius: 2%;
	border: solid;
	border-width: 4px;
	border-color: black;
	/* size: 15%; */
	width: 9%;
}

@media (max-width: 700px) {
	.playerPhoto {
		display: block;
		margin: auto;
		border-radius: 2%;
		border: solid;
		border-width: 4px;
		border-color: black;
		/* size: 15%; */
		width: 25%;
	}
}
