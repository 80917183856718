.home-navigation__menu-btn {
	width: 5rem;
	height: 3rem;
	background: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-right: 2rem;
	cursor: pointer;
}

.home-navigation__menu-btn span {
	display: block;
	width: 3rem;
	height: 2.5px;
	background: white;
}

.navigationTitle {
	color: white;
}

.navigationTitle a {
	text-decoration: none;
	color: white;
}

.navigationTitle a:hover {
	color: white;
	background-color: black;
}

.home-navigation__header-nav {
	display: none;
}

.home-navigation__drawer-nav {
	height: 100%;
}

@media (max-width: 430px) {
	.navigationTitle {
		font-size: x-large;
	}
}

/* @media (max-width: 900px) {
	.navigationTitle {
		font-size: xx-large;
	}
} */

@media (min-width: 768px) {
	.home-navigation__menu-btn {
		display: none;
	}

	.home-navigation__header-nav {
		display: block;
	}
}
